import React from 'react';
import { Box } from '@mui/material';
import CamelotIcon from '/public/icons/camelot.svg';
import UniswapIcon from '/public/icons/uniswap.svg';
import QuickswapIcon from '/public/icons/quickswap.svg';
import { TokenIcon } from '../../../components/primitives/TokenIcon';
import { EMarketName } from '../../../ui-config/marketsConfig';

export interface IListPoolIcon {
  iconSymbolTokenA: string;
  iconSymbolTokenB: string;
  marketName?: EMarketName;
  size?: 'medium' | 'large';
}

export const ListPoolIcon = ({ iconSymbolTokenA, iconSymbolTokenB, marketName, size = 'medium' }: IListPoolIcon) => (
  <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', width: 'min-content' }}>
    <TokenIcon symbol={iconSymbolTokenA} fontSize={size} />
    <TokenIcon
      symbol={iconSymbolTokenB}
      fontSize={size}
      sx={{ marginLeft: -1.6, outline: '2px solid #18181B' }}
    />
    {marketName && (
      <Box sx={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        mr: '-6px',
        mb: '-10px',
        ['>svg']: {
          width: '20px',
          height: '20px',
          border: '2px solid rgb(24, 24, 27)',
          borderRadius: '50%'
        }
      }}>
        {marketName === EMarketName.Uniswap && <UniswapIcon />}
        {marketName === EMarketName.Camelot && <CamelotIcon />}
        {marketName === EMarketName.Quickswap && <QuickswapIcon />}
      </Box>
    )}
  </Box>
);
