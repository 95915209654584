import React from 'react';
import { Box, Chip } from '@mui/material';
import SteerIcon from '../../../public/icons/alm-markets/steer-icon.svg';
import GammaIcon from '../../../public/icons/alm-markets/gamma-icon.svg';
import { EStrategyProvider } from '../../hooks/yldr/useAlmPoolQuery';

export const AlmMarketChip = ({ almMarketName }: { almMarketName?: EStrategyProvider }) => (
  <Chip
    label={(
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        {almMarketName === EStrategyProvider.Steer && <SteerIcon />}
        {almMarketName === EStrategyProvider.Gamma && <GammaIcon />}
        {almMarketName}
      </Box>
    )}
  />
)
