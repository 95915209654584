import { useQuery } from '@tanstack/react-query';
import { FAST_POLLING_INTERVAL, QueryKeys } from '../../ui-config/queries';
import { useProtocolDataContext } from '../useProtocolDataContext';

export enum EStrategyProvider {
  Gamma = 'Gamma',
  Steer = 'Steer',
  DefiEdge = 'DefiEdge',
}

export interface IAlmPoolDataRequest {
  endpointURL: string;
  token0?: string;
  token1?: string;
  clMarketId?: number;
  address?: string;
  chainId?: number;
}

export interface IAlmPoolsResponse {
  market: {
    id: number;
    chain_id: number;
    name: EStrategyProvider;
    create_and_leverage_address: string;
    leverage_address: string;
    leverage_data_provider_address: string;
  },
  cl_market: {
    id: number;
    chain_id: number;
    name: string;
    position_manager_address: string;
    position_wrapper_address: string;
    deposit_zap_address: string;
    data_provider_address: string;
    create_and_leverage_address: string;
    leverage_address: string;
    leverage_data_provider_address: string;
    leverage_automations_address: string;
  },
  fee: number;
  name: string;
  description: string;
  token_0: string;
  token_1: string;
  address: string;
  image?: string;
}

export const getAlmPools = async ({
  endpointURL,
  chainId,
  address,
  token0,
  token1,
  clMarketId,
}: IAlmPoolDataRequest) => {
  try {
    const queryString = new URLSearchParams();
    if (address) queryString.set('address', address);
    if (token0) queryString.set('token_0', token0);
    if (token1) queryString.set('token_1', token1);
    if (chainId) queryString.set('chain_id', chainId.toString());
    if (clMarketId) queryString.set('cl_market_id', clMarketId.toString());
    const url = `${endpointURL}/concentrated_liquidity/alm_pools?${queryString.toString()}`;
    const result = await fetch(url);
    const json: IAlmPoolsResponse[] = await result.json();
    return json;
  } catch (e) {
    return null;
  }
};

const INITIAL_DATA = [] as IAlmPoolsResponse[] ;

export const useAlmPoolsQuery = ({
  chainId,
  address,
  token0,
  token1,
  clMarketId,
  enabled = true,
}: Partial<Omit<IAlmPoolDataRequest, 'endpointURL'>> & { enabled?: boolean; }) => {
  const { currentNetworkConfig } = useProtocolDataContext();
  const endpointURL = currentNetworkConfig.yldrYpiUrl;

  const { data, ...rest } = useQuery({
    queryKey: [QueryKeys.ALM_POOL, chainId, address, token0, token1, clMarketId],
    queryFn: () => getAlmPools({
      endpointURL,
      chainId,
      address,
      token0,
      token1,
      clMarketId,
    }),
    enabled: enabled && (Boolean(token0 && token1 && clMarketId) || Boolean(chainId && address) || Boolean(chainId)),
    refetchInterval: FAST_POLLING_INTERVAL,
  });

  return {
    data: data || INITIAL_DATA,
    ...rest,
  }
}
